html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Mulish', sans-serif;
}

.body-wrapper {
    overflow: hidden;
}

p {
    font-family: 'Poppins';
}


*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
       padding: 0 20px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

h1 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
    padding-bottom: 50px;
    padding-top: 100px;
}

h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 52px;
}

h3 {
    font-weight: 500;
    font-size: 32px;
}

p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 36px;
}

p:not(:last-of-type) {
    padding-bottom: 25px;
}

a { 
    text-decoration: none;
}

li {
    list-style: none;
}


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        padding: 0;
        margin: 0;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }



.site-header-text p {
    padding-bottom: 120px;
}

.orange {
    position: relative;
    z-index: 2;
    display: inline-block;
}

.orange::before{
    position: absolute;
    content: "";
    width: 100%;
    height: 20px;
    background-color: #ffd28e;
    z-index: -1;
    bottom: 7px;
}

.pet {
    position: relative;
    z-index: 2;
}

.pet::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 20px;
    background-color: #dbff8d;
    z-index: -1;
    bottom: 7px;
}



.logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 66px;
}

.nav-bar a {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #000;
    transition: all 0.3s;
}

.nav-bar a:hover {
    color: #FFB241;
}

.site-logo {
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    color: #000;
    padding-left: 50px;
    position: relative;
}

header {
    background-color: #F9F5F0;
    position: relative;
}

header::after {
    position: absolute;
    content: "";
    width: 40px;
    height: 40px;
    background-color: #F9F5F0;
    transform:rotate(45deg);
    left: 50%;
    bottom: -20px;
    border-radius: 9px;
}

header .site-logo::before {
    position: absolute;
    content: "";
    background-image: url(../image/logo.svg);
    background-repeat: no-repeat;
    width: 45px;
    height: 47px;
    left: 0;
    top: -10px;
}

.site-header-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    
}

.site-header-image {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    gap: 50px;
}

.cat-img {
    height: 50%;
    background-image: url(../image/cat.png);
    background-repeat: no-repeat;
    background-size: 185px;
    background-position: right;
}

.bull-img {
    height: 50%;
    background-image: url(../image/dog2.png);
    background-repeat: no-repeat;
    background-size: 240px;
    background-position: right;
}

.dog-img {
    height: 100%;
    position: relative;
}

.dog-img::before {
    position: absolute;
    content: "";
    background-image: url(../image/dog1.png);
    background-repeat: no-repeat;
    background-position: right;
    width: 350px;
    height: 450px;
    top: 60px;
}


/* MAIN  */

.what-is {
    padding: 100px 0 120px;
}

.what-is h2 {
    text-align: center;
    padding-bottom: 40px;
}

.what-img {
    background-color: #CEE2FF;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    border-radius: 9px;
}

.what-img::before {
    position: absolute;
    content: "";
    background-image: url(../image/doge.png);
    width: 289px;
    height: 305px;
    left: calc(50% - 125px);
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
}

.what-wrapper {
    display: grid;
    grid-template-columns: 33% 1fr;
    gap: 50px;
}

.what-content {
    padding: 50px 90px 50px 50px;
    background-color: #F9F5F0;
    position: relative;
}

.what-content::before {
    position: absolute;
    content: "";
    width: 33px;
    height: 33px;
    transform: rotate(45deg);
    left: -16px;
    top: calc(50% - 16px);
    background-color: #F9F5F0;
}

.how-content { 
    padding-top: 100px;
    padding-bottom: 70px;
}

.how h2 {
    padding-bottom: 40px;
}


/* how section  */

.how {
    background-color: #E7FFB4;
}

.how .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.how-img {
    position: relative;
}

.how-img::before {
    position: absolute;
    content: "";
    background-color: #fff;
    width: 100%;
    height: 50%;
    bottom: 0;
    right: -50px;
    border-radius: 9px 9px 0 0;
}

.how-img::after {
    position: absolute;
    content: "";
    background-image: url(../image/pug-min.png);
    background-repeat: no-repeat;
    width: 525px;
    height: 650px;
    background-size: contain;
    bottom: 0;
    left: 50px;
}

.how .container {
    position: relative;
}

.how .container::before {
    position: absolute;
    content: "";
    width: 60px;
    height: 60px;
    background-color: #E7FFB4;
    bottom: -25px;
    border-radius: 9px;
    left: 25px;
    transform: rotate(45deg);
}


/* INFO  */

.info-wrapper {
    padding-top: 70px;
}

.info p{
    padding-bottom: 50px;
    border-bottom: 2px solid #E8EEF3;
}

.last {
    border-bottom: none !important;
}

.info h3 {
    padding-top: 45px;
    padding-bottom: 20px;
    padding-left: 70px;
    position: relative;
}

.info h3::before {
    position: absolute;
    content: "";
    background-size: contain;
    left: 7px;
    background-repeat: no-repeat;
}

#pet-welfare::before {
    background-image: url(../image/health.png);
    width: 43px;
    height: 43px;
}

#pet-bio-access::before {
    background-image: url(../image/mobile.png);
    width: 45px;
    height: 45px;
    
}

#resistant-technology::before {
    background-image: url(../image/shield.png);
    width: 50px;
    height: 50px;
}

#breed-information::before {
    background-image: url(../image/certificate.png);
    width: 47px;
    height: 47px;
}

#physical-item::before {
    background-image: url(../image/qr-code.png);
    width: 43px;
    height: 43px;
}

.our-team {
    display: grid;
    grid-template-columns: 33% 1fr;
    border-radius: 10px;
    background-color: #F9F5F0;
}

.our-team-img {
    background-image: url(../image/our-team.png);
    background-size: cover;
    border-radius: 10px 0 0 10px;
    background-position: center;
}

.our-team-text {
    padding: 55px 60px 60px 69px;
    position: relative;
}

.our-team-text::before {
    position: absolute;
    content: "";
    width: 50px;
    height: 50px;
    transform: rotate(45deg);
    left: -20px;
    top: calc(50% - 16px);
    background-color: #F9F5F0;
    border-radius: 9px;
}

.contact-us {
    text-align: center;
    padding: 90px 100px;
}

.contact-us h2 {
    padding-bottom: 15px;
}

.contact-us p {
    padding-bottom: 45px;
}

.cta {
    background-color: #DBFF8D;
    font-weight: 800;
    font-size: 20px;
    border-radius: 9px;
    border: none;
    padding: 24px 70px;
    cursor: pointer;
    position: relative;
}

footer {
    background-color: #000;
}

footer .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

footer .site-logo {
    color: #fff;
}

footer .site-logo::before {
    position: absolute;
    content: "";
    background-image: url(../image/footer-logo.svg);
    background-repeat: no-repeat;
    width: 45px;
    height: 47px;
    left: 0;
    top: -10px;
}

.copyright {
    color: #B1B1B1;
}

.mobile-doggo {
    display: none;
}

/* TABLET  */

@media only screen and (max-width: 1200px) {
    .site-header-image {
        gap: 0;
    }
}

@media only screen and (max-width: 1000px) {
    .nav-bar a {
        font-size: 17px;
    }

    .nav-bar {
        gap: 22px;
    }

    .site-header-image {
        grid-template-columns: 1fr;
    }

    .grid1 {
        display: none;
    }

    .dog-img::before {
        top: calc(50% - 250px);
        right: calc(50% - 177px);
    }

    .how-img::after {
        width: 603px;
    height: 690px;
    bottom: 0;
    left: 11px;
    }

    .how-img::before {
        right: -10px;
    }

    .our-team {
        grid-template-columns: 40% 1fr;
    }

    .our-team-text {
        padding: 35px 50px 35px 50px;
    }
}

/* MOBILE  */

@media only screen and (max-width: 768px) {

    .container {
        padding: 0 20px;
    }

    h1 {
        font-size: 36px;
        line-height: 42px;
        padding-top: 10px;
        padding-bottom: 0;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-weight: 500;
        font-size: 26px;
    }

    .mobile-doggo {
        display: block;
        width: 100%;
        height: 350px;
        background-image: url(../image/dog1.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: -21px;
        margin-bottom: 10px;
    }




    .site-logo {
        font-weight: 800;
        font-size: 20px;
        line-height: 25px;
    }

    .logo-wrapper {
        justify-content: center;
    }

    .nav-bar,
    .site-header-image{
        display: none;
    }

    .orange::before {
        background-color: transparent;
    }

    .site-header-inner {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .site-header-text p {
        padding-bottom: 45px;
    }

    .what-img::before {
        height: 230px;
    }

    .what-wrapper {
        grid-template-columns: 1fr;
    grid-template-rows: 175px 1fr;
    }

    .what-is h2 {
        padding-bottom: 85px;
    }

    .what-is {
        padding: 60px 0 40px;
    }

    .what-content {
        text-align: center;
        padding: 40px 20px;
    }

    .what-content::before {
        top: -16px;
        left: calc(50% - 16px);
    }

    .how-img {
        height: 250px;
    }

    .how-img::before {
        height: 100%;
        right: -95px;
    }

    .how .container {
        display: flex;
        flex-direction: column;
    }

    .how-img::after {
        width: 303px;
        height: 331px;
        left: auto;
        right: -70px;
    }

    .how-content {
        padding-top: 45px;
        padding-bottom: 110px;
    }

    

    .info {
        background-color: #ECF3FD;
        margin-bottom: 15px;
        border-radius: 9px;
        padding: 10px 20px 30px;
    }

    .info h3 {
        padding-top: 20px;
    }

    .info p { 
        padding-bottom: 0;
    }

    .info h3::before {
        top: calc(50% - 23px);
    }

    .our-team {
        flex-direction: column;
        display: flex;
    }

    .our-team-img {
        height: 171px;
        background-position: center;
        border-radius: 10px;
    }

    .our-team-text {
        text-align: center;
        padding: 25px;
    }

    .our-team-text::before {
        content: none;
    }

    .our-team-text h2 {
        padding-bottom: 10px;
    }

    .contact-us {
        padding: 60px 0;
    }

    footer .container {
        flex-direction: column;
        padding-bottom: 20px;
    }

}
